import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';
import { dashboardTheme } from 'src/theme/theme';

export const Menu: React.FC<IconProps> = ({ ...props }) => {
  return (
    <Icon viewBox="0 0 22 8" {...props}>
      <path
        d="M0.333008 8V5.33333H21.6663V8H0.333008ZM0.333008 2.66667V0H21.6663V2.66667H0.333008Z"
        fill={dashboardTheme.hamburgerMenuIconColor}
      />
      <defs>
        <linearGradient
          id="paint0_linear_1518_39845"
          x1="3.93864"
          y1="1.12676"
          x2="7.39277"
          y2="10.8442"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4B557" />
          <stop offset="0.453125" stopColor="#F48657" />
          <stop offset="1" stopColor="#FD4767" />
        </linearGradient>
      </defs>
    </Icon>
  );
};

export const Ellipsis: React.FC<IconProps> = ({ ...props }) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      viewBox="0 0 448 512"
      {...props}
    >
      <path d="M8 256a56 56 0 1 1 112 0A56 56 0 1 1 8 256zm160 0a56 56 0 1 1 112 0 56 56 0 1 1 -112 0zm216-56a56 56 0 1 1 0 112 56 56 0 1 1 0-112z" />
    </Icon>
  );
};
