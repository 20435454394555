import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useBreakpointValue,
  useColorModeValue
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { alertModalTheme, negotiationPanelTheme } from 'src/theme/theme';

export interface ResponsiveModalProps {
  title?: ReactNode;
  footer?: ReactNode;
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  padding?: any;
  minH?: any;
  minW?: any;
  preventFullscreen?: boolean;
  hideCloseButton?: boolean;
  noMarginBody?: boolean;
  noMarginFooter?: boolean;
}

export const ResponsiveModal: React.FC<ResponsiveModalProps> = ({
  isOpen,
  onClose,
  title,
  children,
  footer,
  padding = {},
  minH = {},
  minW = {},
  preventFullscreen,
  hideCloseButton,
  noMarginBody,
  noMarginFooter
}) => {
  const modalSize = useBreakpointValue({ base: 'full', md: 'md' });
  const bg = alertModalTheme.background;
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={preventFullscreen ? 'md' : modalSize}
    >
      <ModalOverlay backdropFilter="auto" backdropBlur="60px" />
      <ModalContent
        minW={{ base: '100vw', md: '466px', ...minW }}
        p={{ base: '20px', md: '20px', ...padding }}
        minH={{ base: '100vh', md: '538px', ...minH }}
        bgColor={bg}
      >
        <ModalHeader
          fontSize="24px"
          fontWeight="700"
          lineHeight="32px"
          color={alertModalTheme.headingTextColor}
        >
          {title}
        </ModalHeader>
        {!hideCloseButton && (
          <ModalCloseButton
            fontSize={{ base: '17px', md: '18px' }}
            m={{ base: '10px', md: '20px' }}
          />
        )}
        <ModalBody {...(!noMarginBody && { mt: { md: '20px' } })}>
          {children}
        </ModalBody>
        <ModalFooter {...(!noMarginFooter && { mt: { md: '30px' } })}>
          {footer}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
