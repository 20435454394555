import React, { useEffect, useState } from 'react';
import {
  Box,
  Radio,
  RadioGroup,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  HStack,
  Select,
  BoxProps,
  useColorModeValue,
  Text
} from '@chakra-ui/react';
import { Controller } from 'react-hook-form';

import { validate } from '../../utils/validate';
import _, { isArray } from 'lodash';
import { stepThroughTheme } from 'src/theme/theme';
import { SingleDatepicker } from 'chakra-dayzed-datepicker';
import { format, parse } from 'date-fns';
import InputMask from 'react-input-mask';

export interface ChartaFieldProps extends BoxProps {
  field: any;
  register: any;
  getValues: any;
  setValue: any;
  validations: any;
  control: any;
  negotiation?: boolean;
  pathToDefaultValue?: string;
  objectWithDefaultValue?: any;
  resetField?: any;
}

export const ChartaField: React.FC<ChartaFieldProps> = ({
  field,
  register,
  getValues,
  setValue,
  validations,
  control,
  negotiation,
  w,
  pathToDefaultValue,
  objectWithDefaultValue,
  resetField,
  ...props
}) => {
  let component;

  let type = field.component;

  if (negotiation) {
    if (type === 'radio') {
      type = 'select';
    }
  }
  const inputBorderColor =
    props?.borderColor != null ? props?.borderColor : 'white';

  const inputColor =
    props?.color != null ? props?.color : 'rgba(27, 28, 49, 0.5)';

  const selectedRadioBorderColor = 'black';
  const radioBorderColor = useColorModeValue(
    'rgba(27, 28, 49, 0.75)',
    'brand.secondary1'
  );
  const selectedRadioColor = negotiation
    ? inputColor
    : stepThroughTheme.inputTextColor;
  const radioColor = negotiation ? inputColor : stepThroughTheme.inputTextColor;
  useEffect(() => {
    if (pathToDefaultValue && objectWithDefaultValue) {
      if (_.has(objectWithDefaultValue, pathToDefaultValue)) {
        setValue(field.id, _.get(objectWithDefaultValue, pathToDefaultValue));
      }
    }
  }, []);

  const isIsrcNum = (fieldId: string, validations: any) => {
    if (validations != null) {
      const fieldValidations = validations[fieldId];
      if (fieldValidations != null && isArray(fieldValidations)) {
        if (fieldValidations.includes('isrc_number')) {
          return true;
        }
        return false;
      }
      return false;
    }
    return false;
  };
  console.log('@@@ validations', validations);
  switch (type) {
    case 'secondary-input':
    case 'primary-input':
    case 'input':
      component = (
        <InputGroup w={w}>
          {field.prefix && (
            <InputLeftElement color={inputColor}>
              {field.prefix}
            </InputLeftElement>
          )}
          {isIsrcNum(field.id, validations) ? (
            <>
              {' '}
              <Input
                as={InputMask}
                mask="**-***-**-*****"
                alwaysShowMask={true}
                type={field.type}
                placeholder={field.placeholder}
                _placeholder={{
                  color: inputColor
                }}
                {...register(field.id, {
                  validate: () => validate(field.id, getValues(), validations)
                })}
                borderColor={inputBorderColor}
                color={inputColor}
                _hover={{ borderColor: inputBorderColor }}
              />
            </>
          ) : (
            <>
              <Input
                type={field.type}
                placeholder={field.placeholder}
                _placeholder={{
                  color: inputColor
                }}
                {...register(field.id, {
                  validate: () => validate(field.id, getValues(), validations)
                })}
                borderColor={inputBorderColor}
                color={inputColor}
                _hover={{ borderColor: inputBorderColor }}
              />
            </>
          )}

          {field.postfix && (
            <InputRightElement color={inputColor}>
              {field.postfix}
            </InputRightElement>
          )}
        </InputGroup>
      );
      break;
    case 'date':
      component = (
        <InputGroup w={w}>
          {field.prefix && (
            <InputLeftElement color={inputColor}>
              {field.prefix}
            </InputLeftElement>
          )}
          <SingleDatepicker
            name="date-input"
            {...register(field.id, {
              validate: () => validate(field.id, getValues(), validations)
            })}
            date={
              getValues(field.id) != null
                ? parse(getValues(field.id), 'dd/MM/yyyy', new Date())
                : new Date()
            }
            onDateChange={(date) => {
              const dateStr = format(date, 'dd/MM/yyyy');
              // debugger;
              setValue(field.id, dateStr);
            }}
            borderColor={inputColor}
            color={inputColor}
            _hover={{ borderColor: inputBorderColor }}
            configs={{
              dateFormat: 'dd/MM/yyyy'
            }}
            propsConfigs={{
              dateNavBtnProps: {
                background: 'white',
                color: 'black'
              },
              inputProps: {
                className: negotiation ? 'negotiating-date-input' : `date-input`
              },
              popoverCompProps: {
                popoverContentProps: {
                  background: 'white',
                  color: 'black'
                }
              },
              dayOfMonthBtnProps: {
                defaultBtnProps: {
                  color: 'black',
                  background: 'white',
                  _hover: {
                    background: 'gray'
                  }
                },
                isInRangeBtnProps: {
                  color: 'black',
                  background: 'white'
                },
                selectedBtnProps: {
                  color: 'black',
                  background: 'white'
                },
                todayBtnProps: {
                  color: 'black',
                  background: 'white'
                }
              }
            }}
          />

          {field.postfix && (
            <InputRightElement color={inputColor}>
              {field.postfix}
            </InputRightElement>
          )}
        </InputGroup>
      );
      break;
    case 'primary-radio':
    case 'radio':
      component = (
        <Controller
          name={field.id}
          control={control}
          render={({ field: { onChange, value } }) => {
            console.log(
              '@@@ radio value',
              value,
              field.id,
              getValues(field.id)
            );
            return (
              <RadioGroup onChange={onChange} value={value}>
                <HStack w={w}>
                  {field.options.map((option: any) => (
                    <Box
                      key={option.label}
                      pt="4px"
                      pr="16px"
                      pb="4px"
                      pl="4px"
                      onClick={() => {
                        setTimeout(() => {
                          debugger;
                          if (value == option.value) {
                            setValue(field.id, '');
                          }
                        }, 100);
                      }}
                    >
                      <Radio
                        value={option.value}
                        className={
                          negotiation
                            ? 'radio-in-negotiation chakra-radio__control'
                            : ' chakra-radio__control'
                        }
                        {...register(field.id, {
                          validate: () =>
                            validate(field.id, getValues(), validations)
                        })}
                        borderColor={selectedRadioBorderColor}
                      >
                        <Text
                          color={
                            option.value === value
                              ? selectedRadioColor
                              : radioColor
                          }
                        >
                          {option.label}
                        </Text>
                      </Radio>
                    </Box>
                  ))}
                </HStack>
              </RadioGroup>
            );
          }}
        />
      );
      break;
    case 'select':
      component = (
        <Select
          iconColor="black"
          w={w}
          placeholder={field.placeholder}
          {...register(field.id, {
            validate: () => validate(field.id, getValues(), validations)
          })}
          color={inputColor}
          borderColor={inputBorderColor}
          backgroundColor={'white'}
        >
          {field.options.map((opt: any) => (
            <option key={opt.label} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </Select>
      );
      break;
    default:
      component = <Box />;
      break;
  }
  return React.cloneElement(component);
};
