import React, { useEffect, useState } from 'react';
import { Flex, FlexProps, useColorModeValue } from '@chakra-ui/react';
import { Footer } from '../components/core/Footer';
import { useNavigate } from 'react-router-dom';
import { navbarColorAtom, stepThroughDataAtom } from 'src/atoms';
import { useAtom } from 'jotai';
import { ContractSelection } from '../components/pre-step-through/ContractSelection';
import { IndustrySelection } from '../components/pre-step-through/IndustrySelection';
import { RoleSelection } from '../components/pre-step-through/RoleSelection';
import { ChartaBack } from 'src/components/core/ChartaBack';
import { getContractType } from '../api';
import { Answer } from 'src/interfaces';
import { preStepThroughTheme } from 'src/theme/theme';

export interface PreStepThroughProps extends FlexProps {}

export const PreStepThrough: React.FC<PreStepThroughProps> = ({ ...props }) => {
  const navigate = useNavigate();
  const bg = useColorModeValue('#EEEEEE', '#1E2029');
  const mainBg = useColorModeValue(
    'linear-gradient(#FFD3F8, #DCFFF0)',
    'linear-gradient(#654A61, #4A655A)'
  );
  const color = useColorModeValue('#1B1C31', 'brand.secondary1');
  const [, setNavbarColor] = useAtom(navbarColorAtom);
  const [view, setView] = useState<string>('contract');
  const [contractType, setContractType] = useState<any>('');
  const [, setStepThroughData] = useAtom(stepThroughDataAtom);

  useEffect(() => {
    setNavbarColor('#1E2029');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleContractSelected = async (id: string) => {
    const result = await getContractType(id);
    setContractType(result.data);
    setView('role');
  };

  const handleIndustrySelected = (id: string) => {
    setView('contract');
  };

  const handleRoleSelected = (role: string) => {
    let updatedAnswers: Answer[] = [
      {
        stepId: 'role',
        subStepId: 'role',
        questionId: 'role',
        fieldId: 'role',
        value: role
      }
    ];
    setStepThroughData((data) => ({
      ...data,
      formAnswers: updatedAnswers
    }));

    navigate(`/step-through/${contractType._id}`, { replace: true });
  };

  const onBack = () => {
    navigate('/dashboard');
  };

  return (
    <Flex
      background={preStepThroughTheme.background}
      overflowY="hidden"
      flexDirection="column"
      justifyContent="space-between"
      h="100vh"
      overflow="hidden"
      {...props}
    >
      <Flex
        flexGrow="1"
        overflow="hidden"
        justifyContent="center"
        alignItems="center"
      >
        <Flex
          bg={preStepThroughTheme.background}
          borderRadius={{ base: '0', lg: '5px' }}
          h={{ base: '100%', lg: '80%' }}
          w={{ base: '100vw', lg: '655px' }}
          flexDirection="column"
          color={color}
          position="relative"
          overflow="auto"
        >
          {view !== 'industry' && (
            <Flex>
              <ChartaBack label="Back" onClick={() => onBack()} />
            </Flex>
          )}

          <Flex
            p={{ base: '10px 20px', lg: '10px 60px' }}
            alignItems="center"
            flexDirection="column"
          >
            {view === 'industry' ? (
              <IndustrySelection onIndustrySelected={handleIndustrySelected} />
            ) : view === 'contract' ? (
              <ContractSelection onContractSelected={handleContractSelected} />
            ) : (
              <RoleSelection
                onRoleSelected={handleRoleSelected}
                roles={contractType.roles}
              />
            )}
          </Flex>
        </Flex>
      </Flex>
      <Footer display={{ base: 'none', lg: 'initial' }} variant="transparent" />
      <Footer display={{ base: 'initial', lg: 'none' }} />
    </Flex>
  );
};
