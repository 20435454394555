import { Text, Button, Image, Flex } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { verifyEmailTheme } from 'src/theme/theme';

export interface ResetConfirmationProps {}

export const ResetConfirmation: React.FC<ResetConfirmationProps> = ({}) => {
  const navigate = useNavigate();

  return (
    <>
      {/* <Image src="mic.png" alt="Mic" height="248px" /> */}
      <Flex p="32px" flexDirection="column" alignItems="center">
        <Text color={verifyEmailTheme.textColor}>
          Your password has been reset.
        </Text>
        <Button
          mt="48px"
          w="138px"
          h="40px"
          variant="gradient"
          onClick={() => navigate('/login')}
          background={verifyEmailTheme.buttonBackgroundColor}
          color={verifyEmailTheme.buttonTextColor}
        >
          Go to login
        </Button>
      </Flex>
    </>
  );
};
