import { chakra, FlexProps, Flex, Box, Text, Link } from '@chakra-ui/react';
import React from 'react';
import ChartaAccented from './ChartaAccented';
import { footerTheme, universalTheme } from 'src/theme/theme';

export type FooterVariantType = 'default' | 'transparent';
export interface FooterProps extends FlexProps {
  variant?: FooterVariantType;
}

export const Footer: React.FC<FooterProps> = ({
  variant = 'default',
  ...props
}) => {
  return (
    <chakra.footer
      h="77px"
      py="26px"
      px="32px"
      background={
        variant === 'transparent' ? 'transparent' : footerTheme.background
      }
      {...props}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Box w={{ base: '45px', md: '91.75px' }}>
          <img src="/footer_logo.png" />
        </Box>
        <Box>
          <Text
            fontSize="16px"
            fontWeight="400"
            lineHeight="24px"
            style={{
              paddingTop: '10px'
            }}
          >
            &copy; 2023 Charta. All rights reserved.
          </Text>
        </Box>
      </Flex>
    </chakra.footer>
  );
};
