import Validator from 'validatorjs';

export const validate = (field: string, values: any, rules: any) => {
  if (!rules[field]) {
    return true;
  }
  Validator.setAttributeFormatter(function (attribute) {
    let message = attribute.replace(/-/g, ' ');
    const words = message.split(' ');

    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
    return words.join(' ');
  });

  Validator.register(
    'required_value_when',
    function (value: any, requirement, attribute) {
      // requirement parameter defaults to null
      const req = requirement.split(',');
      if (_objectPath(validator.input, req[0]) == req[1] && value != req[2]) {
        return false;
      }
      return true;
    },
    'Must select either Cash Fee and/or Backend Percentage to have a valid contract.'
  );

  Validator.register(
    'alpha_with_spaces',
    function (value: any, requirement, attribute) {
      // requirement parameter defaults to null
      return value.match(/[a-zA-Z ]+[a-zA-Z]$/);
    },
    ':attribute should containt alphabetic characters and spaces only.'
  );
  Validator.register(
    'isrc_number',
    function (value: any, requirement, attribute) {
      // requirement parameter defaults to null
      return value.match(/[0-9a-zA-Z]{2}-[0-9a-zA-Z]{3}-[0-9a-zA-Z]{2}-[0-9a-zA-Z]{5}/g);
    },
    ':attribute number should be formatted as CC-XXX-YY-NNNNN with "-" symbol included.'
  );
  function _objectPath(obj: any, path: any) {
    if (Object.prototype.hasOwnProperty.call(obj, path)) {
      return obj[path];
    }
    let keys = path
      .replace(/\[(\w+)\]/g, '.$1')
      .replace(/^\./, '')
      .split('.');
    let copy: any = {};
    for (let attr in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, attr)) {
        copy[attr] = obj[attr];
      }
    }

    for (let i = 0, l = keys.length; i < l; i++) {
      if (
        typeof copy === 'object' &&
        copy !== null &&
        Object.hasOwnProperty.call(copy, keys[i])
      ) {
        copy = copy[keys[i]];
      } else {
        return;
      }
    }
    return copy;
  }

  let validator = new Validator(
    values,
    { [field]: rules[`${field}`] },
    {
      required: ':attribute is required.'
    }
  );

  if (validator.passes()) {
    return true;
  } else {
    const errors = validator.errors.all();
    return errors[field][0];
  }
};
