import {
  FlexProps,
  Grid,
  GridItem,
  Flex,
  color,
  Button,
  Text,
  Avatar,
  useColorModeValue,
  Box,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Image
} from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { capitalize } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  getOtherPartyUnfinishedContracts,
  getUserUnfinishedContracts
} from 'src/api';
import { negotiation } from 'src/assets/data';
import { dashboardViewAtom, userAtom } from 'src/atoms';
import { dashboardTheme } from 'src/theme/theme';
import { StageStepper } from '../core/StageStepper';
import { NavLink, useNavigate } from 'react-router-dom';
import { Ellipsis, Menu as MenuIcon } from '../icons/Menu';
export interface ContractTableProps extends FlexProps {
  openAlert: any;
  setNegotiationToArchive: any;
  reloadNegotiations: boolean;
  setReloadNegotiations: any;
}

export const ContractTable: React.FC<ContractTableProps> = ({
  reloadNegotiations,
  setReloadNegotiations,
  openAlert,
  setNegotiationToArchive,
  ...props
}) => {
  const [, setView] = useAtom(dashboardViewAtom);
  const color = useColorModeValue('brand.secondary2', 'brand.secondary1');
  const [user] = useAtom(userAtom);
  const [contracts, setContracts] = useState<any[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const getContracts = async () => {
      if (reloadNegotiations) {
        const { data } = await getOtherPartyUnfinishedContracts(3, 0);
        setContracts(data);
        setReloadNegotiations(false);
      }
    };

    getContracts();
  }, [reloadNegotiations]);

  const handleOnClick = async (pipeline: any) => {
    navigate(`/pipeline/${pipeline._id}`);
  };

  return (
    <>
      <Flex gap="36px" alignItems="center">
        <Heading
          fontWeight="700"
          fontSize={{ base: '28px' }}
          lineHeight="48px"
          color={dashboardTheme.primaryTextColor}
        >
          Your Approved Contracts
        </Heading>
        <Button
          variant="transparent"
          h="32px"
          width="129px"
          padding="0 12px 0 12px"
          borderRadius="6px"
          onClick={() => setView('SentOffers')}
          borderColor={dashboardTheme.buttonBackgroundColor}
          // rightIcon={<Dropdown h="6.67px" w="10.83px" />}
        >
          View all
        </Button>
      </Flex>
      <Grid
        // p="16px"
        mb="5px"
        alignItems="center"
        templateColumns={{
          base: 'repeat(7, 1fr)',
          lg: 'repeat(7, 1fr)'
        }}
        borderColor={'black'}
        borderStyle={'solid'}
        borderWidth={'1px'}
        borderLeftWidth={0}
        borderRightWidth={0}
        backgroundColor={dashboardTheme.receivedContractBoxBackground}
        verticalAlign={'middle'}
        height={'50px'}
      >
        <GridItem>
          <Flex flexDirection="column" gap="8.75px">
            <Text color={'black'} textAlign={'left'} fontWeight={700}>
              Client Name
              <Image
                display={'inline-block'}
                verticalAlign={'middle'}
                src="./column_icon.png"
              ></Image>
            </Text>
          </Flex>
        </GridItem>
        <GridItem>
          <Flex flexDirection="column" gap="8.75px">
            <Text color={'black'} textAlign={'center'} fontWeight={700}>
              Matter
              <Image
                display={'inline-block'}
                verticalAlign={'middle'}
                src="./column_icon.png"
              ></Image>
            </Text>
          </Flex>
        </GridItem>
        <GridItem>
          <Flex flexDirection="column">
            <Text color={'black'} textAlign={'left'} fontWeight={700}>
              Contract Type
              <Image
                display={'inline-block'}
                verticalAlign={'middle'}
                src="./column_icon.png"
              ></Image>
            </Text>
          </Flex>
        </GridItem>
        <GridItem>
          <Flex flexDirection="column">
            <Text
              color={dashboardTheme.receivedContractContractTypeTextColor}
              textAlign={'left'}
              fontWeight={700}
            >
              Contract
              <Image
                display={'inline-block'}
                verticalAlign={'middle'}
                src="./column_icon.png"
              ></Image>
            </Text>
          </Flex>
        </GridItem>
        <GridItem>
          <Flex flexDirection="column">
            <Text
              color={dashboardTheme.receivedContractContractTypeTextColor}
              textAlign={'center'}
              fontWeight={700}
            >
              Priority
              <Image
                display={'inline-block'}
                verticalAlign={'middle'}
                src="./column_icon.png"
              ></Image>
            </Text>
          </Flex>
        </GridItem>
        <GridItem>
          <Flex flexDir="column">
            <Text color={color} textAlign={'center'} fontWeight={700}>
              Stage
              <Image
                display={'inline-block'}
                verticalAlign={'middle'}
                src="./column_icon.png"
              ></Image>
            </Text>
          </Flex>
        </GridItem>
        <GridItem>
          {' '}
          <Flex flexDir="column"></Flex>
        </GridItem>
      </Grid>
      {contracts.map((contract) => {
        const pipeline = contract.pipeline;

        return (
          <>
            <Grid
              paddingTop={'16px'}
              paddingBottom={'16px'}
              borderRadius="0px"
              mb="5px"
              key={pipeline._id}
              alignItems="center"
              templateColumns={{
                base: 'repeat(7, 1fr)',
                lg: 'repeat(7, 1fr)'
              }}
              borderColor={'black'}
              borderStyle={'solid'}
              borderWidth={'1px'}
              borderLeftWidth={0}
              borderRightWidth={0}
              backgroundColor={dashboardTheme.receivedContractBoxBackground}
            >
              <GridItem>
                <Flex flexDirection="column" gap="8.75px">
                  <Text color={'black'} textAlign={'left'}>
                    {pipeline.clientName}
                  </Text>
                </Flex>
              </GridItem>
              <GridItem>
                <Flex flexDirection="column">
                  <Text color={'black'} textAlign={'center'}>
                    {pipeline.matterName}
                  </Text>
                </Flex>
              </GridItem>
              <GridItem>
                {/* <Avatar src={otherParty.profileImageUrl} size="sm" /> */}
                <Flex flexDirection="column">
                  <Text
                    color={dashboardTheme.receivedContractContractTypeTextColor}
                    textAlign={'left'}
                  >
                    {pipeline.contractType.name}
                  </Text>
                </Flex>
              </GridItem>
              <GridItem>
                <Flex flexDirection="column" gap="8.75px">
                  <Text color={'black'} textAlign={'left'}>
                    {pipeline.subject}
                  </Text>
                </Flex>
              </GridItem>
              <GridItem>
                {/* <Avatar src={otherParty.profileImageUrl} size="sm" /> */}
                <Flex flexDirection="column">
                  <Text
                    color={dashboardTheme.receivedContractContractTypeTextColor}
                    textAlign={'center'}
                  >
                    {pipeline.priority}
                  </Text>
                </Flex>
              </GridItem>
              <GridItem>
                <StageStepper pipeline={pipeline} />
              </GridItem>
              <GridItem>
                <Flex justifyContent="center" alignItems="center">
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      icon={<Ellipsis height="30px" width="21.3px" />}
                      variant="ghost"
                    />
                    <MenuList zIndex={3}>
                      <MenuItem onClick={() => handleOnClick(pipeline)}>
                        Review
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Flex>
              </GridItem>
            </Grid>
          </>
        );
      })}
    </>
  );
};
