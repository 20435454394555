import api from '../axios/axios';

export const createPipeline = (data: any) => {
  return api.post('/pipeline', data);
};

export const getPipelineById = (id: any) => {
  return api.get(`/pipeline/${id}`);
};

export const updatePipeline = (id: string, data: any) => {
  return api.put(`/pipeline/${id}`, data);
};

export const moveToNextStage = (id: string) => {
  return api.put(`/pipeline/${id}/move-forward`);
};

export const moveToPreviousStage = (id: string) => {
  return api.put(`/pipeline/${id}/move-backward`);
};
