import { chakra, FlexProps, Flex, Box, Text, Link } from '@chakra-ui/react';
import React from 'react';
import ChartaAccented from './ChartaAccented';
import { footerTheme, universalTheme } from 'src/theme/theme';

export interface StageStepperProps extends FlexProps {
  pipeline: any;
  lineZIndex?: number;
}

export const StageStepper: React.FC<StageStepperProps> = ({
  pipeline,
  lineZIndex = -1
}) => {
  return (
    <div style={{ position: 'relative' }}>
      <Flex
        alignItems="center"
        justifyItems={'end'}
        gap="50px"
        mb="16px"
        justifyContent={'center'}
      >
        <hr
          style={{
            backgroundColor: 'black',
            position: 'absolute',
            width: pipeline.approvalFlow.stages.length * 40,
            top: 8,
            zIndex: 1
          }}
        ></hr>
        {pipeline.approvalFlow.stages.map((step: any, index: any) => {
          return (
            <Flex
              flexDirection="column"
              alignItems={'center'}
              justifyContent={'center'}
            >
              {step.name === pipeline.currentStage ? (
                <img
                  src={require('../icons/circle-dot-solid.svg').default}
                  style={{ width: 16, zIndex: 2 }}
                />
              ) : (
                <img
                  src={require('../icons/circle-dot-regular.svg').default}
                  style={{ width: 16, zIndex: 2 }}
                />
              )}
            </Flex>
          );
        })}
      </Flex>
      <Text color={'black'} textAlign={'center'}>
        {pipeline.currentStage}
      </Text>
    </div>
  );
};
