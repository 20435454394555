import React from 'react';
import { useAtom } from 'jotai';
import {
  Box,
  useColorModeValue,
  Flex,
  Text,
  BoxProps,
  Image
} from '@chakra-ui/react';

import { stepThroughDataAtom } from '../../atoms';
import { parseConditions } from '../../utils/parseConditions';
import { parseAnswers } from '../../utils/parseAnswers';
import { ChartaIcon } from '../core/ChartaIcon';
import { stepThroughTheme } from 'src/theme/theme';

export interface StepperProps extends BoxProps {}

export const Stepper: React.FC<StepperProps> = ({ ...props }) => {
  const bg = stepThroughTheme.background;
  const highlight = stepThroughTheme.stepperActiveBackgroundColor;
  const activeColor = stepThroughTheme.stepperActiveColor;
  const inactiveColor = stepThroughTheme.stepperInactiveColor;

  const [stepThroughData] = useAtom(stepThroughDataAtom);
  const {
    contractType: contract,
    wizardIndex,
    formAnswers: answers
  } = stepThroughData;
  const {
    form: { steps },
    conditions
  } = contract!;

  return (
    <Box
      px="8px"
      bg={bg}
      pt="74px"
      pb="68px"
      sx={{ backdropFilter: 'blur(120px)' }}
      w="332px"
      {...props}
    >
      <Flex flexDirection="column" pl="45px" w="100%">
        {steps.map((step: any) => (
          <Box
            key={step.id}
            mb="48px"
            color={stepThroughTheme.stepperInactiveColor}
          >
            <Flex mb="16px">
              <Box>
                <ChartaIcon name={step.icon} h="31" w="37" />
              </Box>
              <Text
                ml="20px"
                fontSize="24px"
                lineHeight="20px"
                fontWeight="600"
              >
                {step.title}
              </Text>
            </Flex>

            {step.subSteps.map((subStep: any) => {
              const condition = conditions.subSteps.find(
                (c: any) => c[subStep.id]
              );

              const selected =
                steps[wizardIndex.step].subSteps[wizardIndex.subStep].id ===
                subStep.id;

              if (
                !condition ||
                (condition && parseConditions(condition, parseAnswers(answers)))
              ) {
                return (
                  <Flex
                    key={subStep.id}
                    h="48px"
                    pl="57px"
                    alignItems="center"
                    borderTopLeftRadius="5px"
                    borderBottomLeftRadius="5px"
                    background={selected ? highlight : 'none'}
                  >
                    <Text color={selected ? activeColor : inactiveColor}>
                      {subStep.title}
                    </Text>
                  </Flex>
                );
              }

              return <Box key={subStep.id}></Box>;
            })}
          </Box>
        ))}

        <Image
          src="/footer_logo_blue.png"
          maxHeight={'70px'}
          style={{
            position: 'absolute',
            bottom: '8em',
            left: '4em'
          }}
        ></Image>
      </Flex>
    </Box>
  );
};
