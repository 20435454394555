import {
  Flex,
  Heading,
  useColorModeValue,
  FlexProps,
  Text,
  FormControl,
  Input,
  Button
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { passwordResetTheme, verifyEmailTheme } from 'src/theme/theme';

export interface EnterCodeProps extends FlexProps {
  onCodeSubmitted: (code: string) => void;
}

export const EnterCode: React.FC<EnterCodeProps> = ({ onCodeSubmitted }) => {
  const bg = verifyEmailTheme.background;
  const color = passwordResetTheme.inputColor;

  const [code, setCode] = useState<string>('');

  return (
    <Flex
      overflow="auto"
      bg={bg}
      borderRadius="5px"
      h={{ base: '100%', lg: 'auto' }}
      w={{ base: '100%', lg: 'auto' }}
      p="32px"
      flexDirection="column"
      alignItems="center"
      color={color}
      filter="drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.25))"
    >
      <Heading
        fontSize="56px"
        fontWeight="400"
        lineHeight="50.4px"
        color={verifyEmailTheme.headingTextColor}
      >
        Email Verification
      </Heading>
      <Text
        align="center"
        mt="24px"
        fontSize="16px"
        fontWeight="400"
        lineHeight="20px"
        color={verifyEmailTheme.textColor}
      >
        Please enter the verification code that was sent to your email.
      </Text>
      <FormControl mt="72px" w={{ base: '100%', lg: '70%' }}>
        <Input
          borderColor={verifyEmailTheme.inputOutlineColor}
          placeholder="Verification code"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          color={verifyEmailTheme.textColor}
          _placeholder={{
            color: color
          }}
        />
      </FormControl>
      <Button
        mt="24px"
        w="138px"
        h="40px"
        variant="gradient"
        borderColor={verifyEmailTheme.inputOutlineColor}
        onClick={() => onCodeSubmitted(code)}
        background={verifyEmailTheme.buttonBackgroundColor}
        color={verifyEmailTheme.buttonTextColor}
      >
        Submit
      </Button>
    </Flex>
  );
};
