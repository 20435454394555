import {
  Flex,
  Box,
  Text,
  useColorModeValue,
  BoxProps,
  Image,
  useColorMode
} from '@chakra-ui/react';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { userAtom } from 'src/atoms';
import { Contract } from 'src/interfaces';
import { contractTheme } from 'src/theme/theme';

export interface VieContractProps extends BoxProps {
  contract: Contract;
  lightUrls: string[];
  darkUrls: string[];
}

export const ViewContract: React.FC<VieContractProps> = ({
  contract,
  lightUrls,
  darkUrls,
  ...props
}) => {
  const titleBg = useColorModeValue('#FFFAF3', 'rgba(0, 0, 0, 0.25)');
  const mainBg = contractTheme.contractOutsideBackground;
  const { colorMode } = useColorMode();
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [pdfUrl, setPdfUrl] = useState<string>('');
  const [user] = useAtom(userAtom);

  useEffect(() => {
    // setImageUrls(lightUrls);
    if (lightUrls.length > 0) {
      setPdfUrl(lightUrls[0]);
    }
    // if (colorMode === 'light') {
    //   setImageUrls(lightUrls);
    // } else {
    //   setImageUrls(darkUrls);
    // }
  }, [colorMode, darkUrls, lightUrls]);

  return (
    <Box
      {...props}
      h="100%"
      overflow="hidden"
      borderRadius="5px"
      paddingBottom="10px"
    >
      <Flex
        justifyContent="center"
        bg={titleBg}
        paddingY="17px"
        borderTopRadius="5px"
      >
        {((user?.email === contract.initiatorEmail &&
          !contract.initiatorSignedAt) ||
          (user?.email === contract.receiverEmail &&
            !contract.receiverSignedAt)) && (
          <Text
            textAlign="center"
            fontSize="20px"
            fontWeight="600"
            lineHeight="24px"
          >
            Please review your contract carefully before signing it
          </Text>
        )}
      </Flex>
      <Box bg={mainBg} h="100%">
        <Flex
          height="95%"
          overflowY="auto"
          flexDirection="column"
          alignItems="center"
          paddingY="10px"
        >
          <iframe src={pdfUrl} height="100%" width="100%"></iframe>
          {/* {imageUrls.map((url, index) => (
            <Image
              pointerEvents="none"
              src={url}
              alt={`page ${index}`}
              mb="10px"
              key={index}
              w={{ lg: '50%' }}
            />
          ))} */}
        </Flex>
      </Box>
    </Box>
  );
};
