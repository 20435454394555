import api from '../axios/axios';

export const createContractDocument = async (data: any) => {
  return await api.post('/document', data);
};

export const getContractDocument = (id: string) => {
  return api.get(`/document/${id}`);
};

export const downloadPipelinePdf = (pipelineId: string) => {
  return api.get(`/document/getPdfById/${pipelineId}`);
};

export const downloadPipelineWord = (pipelineId: string) => {
  return api.get(`/document/getDocxById/${pipelineId}`);
};
