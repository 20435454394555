import { Box, Button, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { Warning } from '../icons/Warning';
import { Success } from '../icons/Success';
import { ResponsiveModal } from './ResponsiveModal';
import { alertModalTheme } from 'src/theme/theme';

export type AlertVariant = 'warning' | 'success' | 'confirmation';

export interface AlertModalProps {
  variant: AlertVariant;
  acceptText: string;
  isOpen: boolean;
  onAccept: () => void;
  title?: string;
  text?: string;
  text2?: string;
  declineText?: string;
  onDecline?: () => void;
}

export const AlertModal: React.FC<AlertModalProps> = ({
  variant,
  title,
  text,
  text2,
  acceptText,
  declineText,
  onAccept,
  onDecline,
  isOpen,
  ...props
}) => {
  const onClose = onDecline ? onDecline : () => {};

  return (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={onClose}
      hideCloseButton={true}
      {...props}
    >
      <Flex
        flexDirection="column"
        alignItems="center"
        gap="60px"
        bg={alertModalTheme.background}
      >
        {variant === 'warning' ? (
          <Warning height="95px" width="109.39px" color="#EEA210" />
        ) : variant === 'success' ? (
          <Success height="162px" width="162px" color="#29C011" />
        ) : variant === 'confirmation' ? (
          <Box></Box>
        ) : (
          <Box></Box>
        )}

        <Flex flexDirection="column" alignItems="center">
          <Text
            align="center"
            fontWeight="700"
            color={alertModalTheme.headingTextColor}
          >
            {title}
          </Text>
          <Text align="center" color={alertModalTheme.textColor}>
            {text}
          </Text>
          {text2 != null && (
            <>
              <br />
              <Text align="center" color={alertModalTheme.textColor}>
                {text2}
              </Text>
            </>
          )}
        </Flex>

        <Flex gap="8px" justifyContent="center" mt="10px">
          <Button
            variant="outline"
            onClick={() => onClose()}
            hidden={!declineText}
          >
            {declineText}
          </Button>
          <Button
            onClick={() => onAccept()}
            color={alertModalTheme.buttonTextColor}
          >
            {acceptText}
          </Button>
        </Flex>
      </Flex>
    </ResponsiveModal>
  );
};
