import React, { useEffect } from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';
import { Footer } from '../components/core/Footer';
import { EnterCode } from '../components/verify/EnterCode';
import { verifyUser } from 'src/api';
import { useAtom } from 'jotai';
import { redirectAtom, userAtom, navbarColorAtom } from 'src/atoms';
import { Navigate, useNavigate } from 'react-router-dom';
import { handleDisplayResponseError } from 'src/utils/handleDisplayResponseError';

export interface VerifyProps extends FlexProps {}

export const Verify: React.FC<VerifyProps> = ({ ...props }) => {
  const [user, setUser] = useAtom(userAtom);
  const [navbarColor, setNavbarColor] = useAtom(navbarColorAtom);
  const navigate = useNavigate();
  const [redirect] = useAtom(redirectAtom);

  const verify = async (verificationCode: string) => {
    try {
      const response = await verifyUser({ verificationCode });
      setUser(response.data);
      navigate('/verify-complete');
      // if (redirect) {
      //   navigate(redirect);
      // } else {
      //   navigate('/');
      // }
    } catch (err) {
      handleDisplayResponseError(err);
    }
  };

  useEffect(() => {
    setNavbarColor('#1E2029');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (user?.role !== 'UnverifiedUser') {
    return <Navigate to={'/'} />;
  }

  return (
    <Flex
      // bgImage="url(/reset.png)"
      // bgPosition="center"
      // bgSize="cover"
      overflowY="hidden"
      flexDirection="column"
      justifyContent="space-between"
      h="100vh"
      overflow="hidden"
      {...props}
    >
      <Flex
        flexGrow="1"
        overflow="hidden"
        justifyContent="center"
        alignItems="center"
      >
        <EnterCode onCodeSubmitted={verify} />
      </Flex>
      <Footer />
    </Flex>
  );
};
