import { accordionAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(accordionAnatomy.keys);

const summary = definePartsStyle((props) => {
  return {
    container: {
      border: 'none'
    },
    button: {
      background: mode('brand.secondary1', '#1B1C31')(props),
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
      _hover: {
        background: mode('brand.secondary1', '#1B1C31')(props)
      }
    },
    panel: {
      paddingX: '0'
    }
  };
});

const panel = definePartsStyle((props) => {
  return {
    container: {
      border: 'none',
      borderRadius: '8px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      overflow: 'hidden'
    },
    button: {
      padding: '12px 16px 13px 16px',
      background: 'black',
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
      _hover: {
        background: 'black'
      },
      marginTop: '8px',
      _expanded: {
        padding: '12px 16px 0px 16px',
        borderBottomLeftRadius: '0',
        borderBottomRightRadius: '0',
        background: 'black',
        _hover: {
          background: 'black'
        }
      }
    },
    panel: {
      background: 'black',
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',
      height: { base: 'auto', lg: '460px' },
      pb: '8px',
      overflow: { lg: 'hidden' }
    }
  };
});

const variants = {
  panel,
  summary
};

export const accordionTheme = defineMultiStyleConfig({
  variants
});
